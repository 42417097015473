<template>
    <section class="data-list-container">
        <vx-card title="Tabel permisiuni">

            <div v-if="roles.length">

                <v-simple-table>
                    <template v-slot:default>
                        <thead>
                        <tr>
                            <td>
                                Permisiune
                            </td>
                            <td v-for="role in roles" :key="role.id">
                                <span class="capitalize">{{ role.name }}</span>
                            </td>
                        </tr>
                        </thead>
                        <tbody>
                        <tr v-for="permission in permissions" :key="permission.id">
                            <td>{{ permission.nice_name }}</td>
                            <td v-for="role in roles" :key="role.id">
                                <toggle-permission :permission="permission" :role="role"/>
                            </td>
                        </tr>
                        </tbody>
                    </template>
                </v-simple-table>

            </div>
            <div v-else>
                Nu sunt roluri
            </div>
        </vx-card>
    </section>
</template>

<script>
import TogglePermission from '@/views/Roles/components/togglePermission'

export default {
    name: 'PermissionsList',
    components: {
        TogglePermission
    },
    data() {
        return {
            paginatedRoles: {
                data: []
            },
            permissions: []
        }
    },
    computed: {
        roles() {
            return this.paginatedRoles.data
        }
    },
    methods: {
        loadRoles() {
            this.$http.get('roles')
                .then(({data}) => {
                    this.paginatedRoles = data
                })
        },
        loadPermissions() {
            this.$http.get('roles/permissions')
                .then(({data}) => {
                    this.permissions = data
                })
        }
    },
    mounted() {
        this.loadRoles()
        this.loadPermissions()
    }
}
</script>
