<template>
  <div>
    <v-switch
      v-model="permissionValue"
      :loading="isLoading"
      inset
    ></v-switch>
  </div>
</template>

<script>
export default {
  name: 'togglePermission',
  data () {
    return {
      isLoading: false
    }
  },
  props: {
    role: {
      required: true
    },
    permission: {
      required: true
    }
  },
  computed: {
    permissionValue: {
      get () {
        return this.permission.status_on_roles[this.role.id]
      },
      set () {
        this.isLoading = true
        this.$http.patch(`roles/permissions/${this.role.id}/${this.permission.id}`)
          .then(() => {
            this.isLoading = false
          })
      }
    }
  }
}
</script>
